<template>
  <div class="crm b-segment" style="display: block">
    <div class="crm__header b-segment__header">
      <p class="crm__header-title title-m-bold neutral-900--text">
        {{ program.name }}
      </p>

      <div>
        <v-btn
          v-if="userPermissionsSet.has('program-account-import')"
          class="crm__header_new-btn"
          style="margin-right: 25px"
          color="secondary"
          :text="true"
          :ripple="false"
          @click="toRoute('/program/accounts/import')"
        >
          <iconify-icon
            icon="clarity-import-line"
            width="21"
            style="margin-right: 5px"
          />
          Импорт
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="
                userPermissionsSet.has('program-account-export') ||
                authUser.is_super_admin
              "
              class="crm__header_new-btn"
              style="margin-right: 25px"
              color="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <iconify-icon
                icon="clarity-export-line"
                width="21"
                style="margin-right: 5px"
              />
              Экспорт
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in [
                { text: 'xlsx', value: 'xlsx' },
                { text: 'csv', value: 'csv' },
              ]"
              :key="index"
              @click="exportAccountFields(item.value)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="userPermissionsSet.has('program-account-create')"
          class="crm__header_new-btn"
          color="primary"
          @click="sidePanelNewClientStatus = true"
        >
          <iconify-icon icon="plus-circle" width="21" />
          <p class="body-m-semibold neutral-100--text">Новый клиент</p>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab color="primary" elevation="2" class="ml-1" v-bind="attrs" v-on="on">
              <v-icon small> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="deleteAllUsers">
              <v-list-item-title>Удалить все</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userPermissionsSet.has('program-account-update-all')" @click="clickConsentSms">
              <v-list-item-title>Установить всем согласие на СМС </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="userPermissionsSet.has('program-account-update-all')" @click="clickConsentAdvertising">
              <v-list-item-title>Установить всем согласие на Рекламу</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-row
    v-if="
        userPermissionsSet.has('program-account-list') ||
        authUser.is_super_admin
      "
     style="margin-top: 34px">
      <v-col>
        <div class="body-l-semibold neutral-900--text">Работа с клиентами</div>
        <div class="body-m-regular neutral-700--text">
          Для списания или начисления бонусов найдите клиента используя поле
          ниже. Вводите любые данные клиента: ФИО, номер карты, штрих-код,
          электронная почта, номер телефона.
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        userPermissionsSet.has('program-account-list') ||
        authUser.is_super_admin
      "
    >
      <v-col>
        <client-table :routeSegmentId="routeSegmentId" ref="clientsTable" />
      </v-col>
    </v-row>

    <side-panel-new-client
      v-model="sidePanelNewClientStatus"
      @success="refreshData"
    />
  </div>
</template>

<script>
import ClientTable from "./Table";
import SidePanelNewClient from "./components/SidePanelNewClient";
import routing from "@/mixins/routing";
import Permission from "@/mixins/permission";

export default {
  components: {
    ClientTable,
    SidePanelNewClient,
  },
  mixins: [routing, Permission],
  props: ['routeSegmentId'],
  data() {
    return {
      importDialog: false,
      sidePanelNewClientStatus: false,
    };
  },
  computed: {
    program() {
      return this.$store.getters["company/program/program"];
    },
    filterStore() {
      return this.$store.getters["crm/client/filter"];
    },
  },
  watch: {},
  created() {},
  mounted() {
    console.log(this.authUser)
  },
  methods: {
    refreshData() {
      this.$refs.clientsTable.fetchData();
    },
    async exportAccountFields(type) { //
      await this.$store.dispatch("account/account/exportAccountFields", {
        programId: this.program.id,
        type,
        email: this.authUser.email
      });
    },
    async deleteAllUsers() {
      try {
          await this.$alert(
            "Подтвердите удаление всех клиентов в корзину",
            this.$t('Удаление всех клиентов в корзину'),
            {
              confirmButtonText: "Ок",
              type: 'warning',
            },
          )
          
          await this.$store.dispatch("crm/client/deleteAll",
              this.program.id,
          )
        } catch {
        } 
    },

    async clickConsentSms() {
      try {
        await this.$confirm(
            `Вы уверены, что хотите установить всем клиентам согласие на СМС?`,
            "Проставление согласий",
            {
              confirmButtonText: "Проставить",
              cancelButtonText: "Отмена",
              type: "warning",
            }
        );
        await this.$store.dispatch("crm/client/consentAllSms", {
          program_id: this.program.id
        })
        this.$notify({
          type: 'success',
          title: 'Клиенты',
          text: 'Установлены всем клиентам согласие на СМС',
        })
      } catch {}
    },

    async clickConsentAdvertising() {
      try {
        await this.$confirm(
            `Вы уверены, что хотите установить всем клиентам согласие на Рекламу`,
            "Проставление согласий",
            {
              confirmButtonText: "Проставить",
              cancelButtonText: "Отмена",
              type: "warning",
            }
        );
        await this.$store.dispatch("crm/client/consentAllAdvertising", {
          program_id: this.program.id
        })
        this.$notify({
          type: 'success',
          title: 'Клиенты',
          text: 'Установлены всем клиентам согласие на Рекламу',
        })
      } catch {}
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/crm/_crm.scss";
</style>
