var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.clientsStore.length && !_vm.filter)?_c('empty-client'):_c('div',[(false)?_c('v-row',[_c('v-col',[_c('v-switch',{staticClass:"switch__filter-mode",attrs:{"label":!_vm.filterMode ? 'Обычный' : 'Расширенный'},model:{value:(_vm.filterMode),callback:function ($$v) {_vm.filterMode=$$v},expression:"filterMode"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticStyle:{"margin":"-5px 0 15px 0"}},[_c('NewClientFilter',{staticClass:"mt-3",attrs:{"routeSegmentId":_vm.routeSegmentId}})],1)])],1),_c('v-row',[_c('v-col',{staticStyle:{"width":"1024px"},attrs:{"cols":"12"}},[_c('base-table',{staticClass:"client-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.clients,"headerProps":{'sort-icon':'$iconify_feather-chevron-up'},"options":_vm.tableOptions,"word-operations":['клиент', 'клиента', 'клиентов'],"show-expand":false,"server-items-length":_vm.totalCount,"storage-key":_vm.tableKey},on:{"update:options":function($event){_vm.tableOptions=$event},"update:sort-by":function($event){return _vm.fetchData('sort-by')},"update:sort-desc":function($event){return _vm.fetchData('sort-desc')},"click:row":_vm.editSidePanel},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-s-semibold neutral-900--text"},[_vm._v(" "+_vm._s(item.id)+" ")]),_c('div',{staticClass:"body-xs-semibold neutral-600--text",staticStyle:{"margin-top":"4px"}},[_vm._v(" "+_vm._s(item.barcode ? item.barcode : '-')+" ")])]}},{key:"item.user.FIO",fn:function(ref){
var item = ref.item;
return [_c('account-column',{attrs:{"value":item,"show-gender":false}}),(false)?_c('div',{staticClass:"table-cell_avatar"},[_c('img',{staticClass:"table-cell_avatar-img",attrs:{"src":(item.user && item.user.avatar) ? item.user.avatar : ''}}),_c('div',{staticClass:"table-cell_avatar-text"},[_c('div',{class:['body-s-semibold', 'table-cell_avatar-text2', (item.client_name? 'neutral-900--text': 'neutral-700--text')]},[_vm._v(" "+_vm._s(item.client_name || _vm.$t('no_name'))+" ")]),_c('div',{staticClass:"body-xs-semibold neutral-600--text table-cell_avatar-text2",staticStyle:{"margin-top":"4px"}},[_vm._v(" "+_vm._s((item.user && item.user.last_activity) ? _vm.getLastActivity(item.user.last_activity) : '')+" ")])])]):_vm._e()]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('gender-column',{attrs:{"value":item.gender}})]}},{key:"item.main_balance",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-s-semibold neutral-900--text"},[_vm._v(" "+_vm._s(item.main_balance.toLocaleString())+" ")])]}},{key:"item.sales_cash_value_sum",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"body-s-semibold neutral-900--text"},[_vm._v(" "+_vm._s(item.sales_cash_value_sum !== null ? (item.sales_cash_value_sum / 100 ).toLocaleString(undefined, {style: _vm.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _vm.program.currency.alpha3}) : 0)+" ")])]}},{key:"item.segments",fn:function(ref){
var item = ref.item;
return [(item.segments && item.segments.length)?_c('v-row',[_c('v-col',_vm._l((item.segments),function(segment,i){return _c('segment-chip',{key:("segment" + i),staticClass:"ma-1",attrs:{"name":segment.name,"color":segment.color,"is_public":segment.is_public,"chip":""}})}),1)],1):_vm._e()]}},{key:"item.user.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-s-semibold neutral-900--text"},[(item.user && item.user.phone)?_c('a',{attrs:{"href":'tel:+' + item.user.phone},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("+"+_vm._s(item.user.phone))]):_c('div',[_vm._v("-")])]),_c('div',{staticClass:"body-xs-semibold neutral-600--text",staticStyle:{"margin-top":"4px"}},[_vm._v(" "+_vm._s((item.user && item.user.email) ? item.user.email : '-')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-s-semibold neutral-900--text"},[_vm._v(" "+_vm._s(_vm.getDate(item.created_at))+" ")]),_c('div',{staticClass:"body-xs-semibold neutral-600--text",staticStyle:{"margin-top":"4px"}},[_vm._v(" "+_vm._s(_vm.getTime(item.created_at))+" ")])]}},{key:"item.data-table-expand",fn:function(){return [_c('v-icon',{attrs:{"color":"neutral-500","size":"11"}},[_vm._v(" fas fa-chevron-right ")])]},proxy:true}],null,true)})],1)],1)],1),_c('side-panel-edit-client',{attrs:{"mode":_vm.sidePanelStatus.mode,"data":_vm.sidePanelStatus.data},on:{"update:mode":function($event){return _vm.$set(_vm.sidePanelStatus, "mode", $event)}},model:{value:(_vm.sidePanelStatus.active),callback:function ($$v) {_vm.$set(_vm.sidePanelStatus, "active", $$v)},expression:"sidePanelStatus.active"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }