var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crm b-segment",staticStyle:{"display":"block"}},[_c('div',{staticClass:"crm__header b-segment__header"},[_c('p',{staticClass:"crm__header-title title-m-bold neutral-900--text"},[_vm._v(" "+_vm._s(_vm.program.name)+" ")]),_c('div',[(_vm.userPermissionsSet.has('program-account-import'))?_c('v-btn',{staticClass:"crm__header_new-btn",staticStyle:{"margin-right":"25px"},attrs:{"color":"secondary","text":true,"ripple":false},on:{"click":function($event){return _vm.toRoute('/program/accounts/import')}}},[_c('iconify-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"clarity-import-line","width":"21"}}),_vm._v(" Импорт ")],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
              _vm.userPermissionsSet.has('program-account-export') ||
              _vm.authUser.is_super_admin
            )?_c('v-btn',_vm._g(_vm._b({staticClass:"crm__header_new-btn",staticStyle:{"margin-right":"25px"},attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_c('iconify-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"clarity-export-line","width":"21"}}),_vm._v(" Экспорт ")],1):_vm._e()]}}])},[_c('v-list',_vm._l(([
              { text: 'xlsx', value: 'xlsx' },
              { text: 'csv', value: 'csv' } ]),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.exportAccountFields(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),(_vm.userPermissionsSet.has('program-account-create'))?_c('v-btn',{staticClass:"crm__header_new-btn",attrs:{"color":"primary"},on:{"click":function($event){_vm.sidePanelNewClientStatus = true}}},[_c('iconify-icon',{attrs:{"icon":"plus-circle","width":"21"}}),_c('p',{staticClass:"body-m-semibold neutral-100--text"},[_vm._v("Новый клиент")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"fab":"","color":"primary","elevation":"2"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.deleteAllUsers}},[_c('v-list-item-title',[_vm._v("Удалить все")])],1),(_vm.userPermissionsSet.has('program-account-update-all'))?_c('v-list-item',{on:{"click":_vm.clickConsentSms}},[_c('v-list-item-title',[_vm._v("Установить всем согласие на СМС ")])],1):_vm._e(),(_vm.userPermissionsSet.has('program-account-update-all'))?_c('v-list-item',{on:{"click":_vm.clickConsentAdvertising}},[_c('v-list-item-title',[_vm._v("Установить всем согласие на Рекламу")])],1):_vm._e()],1)],1)],1)]),(
      _vm.userPermissionsSet.has('program-account-list') ||
      _vm.authUser.is_super_admin
    )?_c('v-row',{staticStyle:{"margin-top":"34px"}},[_c('v-col',[_c('div',{staticClass:"body-l-semibold neutral-900--text"},[_vm._v("Работа с клиентами")]),_c('div',{staticClass:"body-m-regular neutral-700--text"},[_vm._v(" Для списания или начисления бонусов найдите клиента используя поле ниже. Вводите любые данные клиента: ФИО, номер карты, штрих-код, электронная почта, номер телефона. ")])])],1):_vm._e(),(
      _vm.userPermissionsSet.has('program-account-list') ||
      _vm.authUser.is_super_admin
    )?_c('v-row',[_c('v-col',[_c('client-table',{ref:"clientsTable",attrs:{"routeSegmentId":_vm.routeSegmentId}})],1)],1):_vm._e(),_c('side-panel-new-client',{on:{"success":_vm.refreshData},model:{value:(_vm.sidePanelNewClientStatus),callback:function ($$v) {_vm.sidePanelNewClientStatus=$$v},expression:"sidePanelNewClientStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }