<template>
  <div>
    <empty-client
      v-if="!clientsStore.length && !filter"
    />
    <div
      v-else
    >
      <v-row v-if="false">
        <v-col>
          <v-switch
            v-model="filterMode"
            class="switch__filter-mode"
            :label="!filterMode ? 'Обычный' : 'Расширенный'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div style="margin: -5px 0 15px 0;">
            <NewClientFilter :routeSegmentId="routeSegmentId"  class="mt-3"/>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          style="width: 1024px"
        >
          <base-table
            class="client-table"
            :headers="tableHeaders"
            :items="clients"
            :headerProps="{'sort-icon':'$iconify_feather-chevron-up'}"
            :options.sync="tableOptions"
            :word-operations="['клиент', 'клиента', 'клиентов']"
            :show-expand="false"
            :server-items-length="totalCount"
            :storage-key="tableKey"
            @update:sort-by="fetchData('sort-by')"
            @update:sort-desc="fetchData('sort-desc')"
            @click:row="editSidePanel"
          >
            <template v-slot:[`item.id`]="{ item }">
              <div class="body-s-semibold neutral-900--text">
                {{ item.id }}
              </div>
              <div
                class="body-xs-semibold neutral-600--text"
                style="margin-top: 4px;"
              >
                {{ item.barcode ? item.barcode : '-' }}
              </div>
            </template>

            <template v-slot:[`item.user.FIO`]="{ item }">
              <account-column
                :value="item"
                :show-gender="false"
              />
              <div
                v-if="false"
                class="table-cell_avatar"
              >
                <img
                  class="table-cell_avatar-img"
                  :src="(item.user && item.user.avatar) ? item.user.avatar : ''"
                >
                <div class="table-cell_avatar-text">
                  <div
                    :class="['body-s-semibold', 'table-cell_avatar-text2', (item.client_name? 'neutral-900--text': 'neutral-700--text')]"
                  >
                    <!-- {{ getFIO(item.user) + ( (item.client_name && item.client_name != item.user.FIO) ? ` (${item.client_name})` : '' ) }} -->
                    {{ item.client_name || $t('no_name') }}
                  </div>
                  <div
                    class="body-xs-semibold neutral-600--text table-cell_avatar-text2"
                    style="margin-top: 4px;"
                  >
                    {{ (item.user && item.user.last_activity) ? getLastActivity(item.user.last_activity) : '' }}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              <gender-column :value="item.gender" />
            </template>
            <template v-slot:[`item.main_balance`]="{ item }">
              <span class="body-s-semibold neutral-900--text"> {{ item.main_balance.toLocaleString() }} </span>
            </template>
            <template v-slot:[`item.sales_cash_value_sum`]="{ item }">
              <span class="body-s-semibold neutral-900--text"> {{ item.sales_cash_value_sum !== null ? (item.sales_cash_value_sum / 100 ).toLocaleString(undefined, {style: program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: program.currency.alpha3}) : 0 }} </span>
            </template>
            <template v-slot:[`item.segments`]="{ item }">
              <v-row
                v-if="item.segments && item.segments.length"
              >
                <v-col>
                  <segment-chip
                    v-for="(segment, i) in item.segments"
                    :key="`segment${i}`"
                    :name="segment.name"
                    :color="segment.color"
                    :is_public="segment.is_public"
                    class="ma-1"
                    chip
                  />
                </v-col>

                <!-- <div
                  v-for="(segment, i) in item.segments"
                  :key="`segment${i}`"
                  class="segment__name body-s-semibold "
                  :style="segment.color != undefined ? `color: ${segment.color}; background: ${hexToRgbA(segment.color, '0.15')}` : ''"
                >
                  <v-tooltip
                    dark
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        style="max-width:20rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                        v-on="on"
                      >
                        {{ segment.name }}
                      </span>
                    </template>
                    <div style="max-width:40rem; word-break: break-word;">
                      {{ segment.name }}
                    </div>
                  </v-tooltip>
                </div> -->
              </v-row>
              <!-- <div v-else>
                -
              </div> -->
            </template>

            <template v-slot:[`item.user.phone`]="{ item }">
              <div class="body-s-semibold neutral-900--text">
                <a @click.stop="" v-if="item.user && item.user.phone" :href="'tel:+' + item.user.phone ">+{{ item.user.phone }}</a>
                <div v-else>-</div>
              </div>
              <div
                class="body-xs-semibold neutral-600--text"
                style="margin-top: 4px;"
              >
                {{ (item.user && item.user.email) ? item.user.email : '-' }}
              </div>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <div class="body-s-semibold neutral-900--text">
                {{ getDate(item.created_at) }}
              </div>
              <div
                class="body-xs-semibold neutral-600--text"
                style="margin-top: 4px;"
              >
                {{ getTime(item.created_at) }}
              </div>
            </template>

            <template v-slot:[`item.data-table-expand`]>
              <v-icon
                color="neutral-500"
                size="11"
              >
                fas fa-chevron-right
              </v-icon>
            </template>
          </base-table>
        </v-col>
      </v-row>
    </div>

    <side-panel-edit-client

      v-model="sidePanelStatus.active"
      :mode.sync="sidePanelStatus.mode"
      :data="sidePanelStatus.data"
    />
  </div>
</template>

<script>
  import NewClientFilter from './filter/Filter.vue'
  import EmptyClient from './Empty'
  import Convertor from '@/mixins/convertor'
  import User from '@/mixins/user.js'
  import axios from 'axios'
  import SegmentChip from '@/components/segment/SegmentChip'
  const CancelToken = axios.CancelToken

  export default {
    components: {
      NewClientFilter,
      EmptyClient,
      AccountColumn: () => import('@/components/colums/AccountColumn.vue'),
      GenderColumn: () => import('@/components/colums/GenderColumn.vue'),
      SegmentChip,
    },
    mixins: [Convertor, User],
    props: ['routeSegmentId'],
    data () {
      return {
        cancelTokenSource: CancelToken.source(),
        loadingList: false,
        tableKey: 'CRMClientTable',
        tableOptions: {
          multiSort: true,
          page: 1,
          itemsPerPage: 25,
        },
        filterMode: false,
        sidePanelStatus: {
          active: false,
          mode: false,
          data: null,
        },
        timerId : '',
        tableHeaders: [
          { text: 'Карта клиента\n(ID, Номер карты)', align: 'start', value: 'id', width: '15em', class: 'pre-header' },
          { text: 'Клиент', align: 'start', value: 'user.FIO', width: '25em', sortable: false },
          { text: 'Пол', align: 'start', value: 'gender', width: '5em', sortable: false },
          { text: 'Баланс', align: 'end', value: 'main_balance', width: '10em', sortable: true },
          { text: 'Сегмент', align: 'start', value: 'segments', sortable: false },
          { text: 'Контакты\n(Телефон, Email)', align: 'start', value: 'user.phone', width: '16em', sortable: false, class: 'pre-header' },
          { text: 'Создание', align: 'start', width: '11em', value: 'created_at' },
          { text: 'Сумма оплат', align: 'end', value: 'sales_cash_value_sum', sortable: true},
          { text: 'Кол-во продаж', align: 'end', value: 'sales_count', sortable: true},
          { text: '', width: '1em', align: 'end', value: 'data-table-expand' },
        ],
        wordPages: ['странице', 'страницах', 'страницах'],
        wordOperations: ['клиент', 'клиента', 'клиентов'],
      }
    },
    computed: {
      program () {
        return this.$store.getters['company/program/program']
      },
      clientsStore () {
        return this.$store.getters['crm/client/clients']
      },
      filteredClients () {
        return this.$store.getters['crm/client/filteredClients']
      },
      clients () {
        return this.filteredClients.length
          ? this.filteredClients
          : this.$store.getters['crm/client/clients']
      },
      totalCount () {
        return this.filteredClients.length || this.$store.getters['crm/client/total']
      },
      filter () {
        return this.$store.getters['crm/client/filter']
      },
      search () {
        return this.$store.getters['crm/client/search']
      },
      filterDefault () {
        return this.$store.getters['crm/client/filterDefault']
      },
      list: {
        get () {
          return this.$store.getters['crm/client/list']
        },
        set (v) {
          this.$store.commit('crm/client/SET_LIST', v)
        },
      },
      queryValue () {
        return this.$store.getters['crm/client/queryValue']
      },
      getOffset() {
        return (this.tableOptions.page * this.tableOptions.itemsPerPage ) - this.tableOptions.itemsPerPage
      },
    },
    watch: {
      'program.id' (v) {
        if (v) {
          this.$store.commit('crm/client/SET_FILTER', JSON.parse(JSON.stringify(this.filterDefault)))
          this.resetPagination()
          this.fetchData('watch.program.id')
        }
      },
      filter (newValue, oldValue) {
        console.log('table.watch.filter', oldValue, newValue)
        this.resetPagination()
        this.fetchData('watch.filter')
      },
      search (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.resetPagination()
          this.fetchData('watch.filter')
        }
      },
      'tableOptions.page' (v) {
        if (v) this.fetchData('tableOptions.page')
      },
      'tableOptions.itemsPerPage' (v) {
        if (v) this.fetchData('tableOptions.itemsPerPage')
      },
    },
    destroyed () {
      this.$store.commit('crm/client/SET_FILTERED_CLIENTS', [])
      clearInterval(this.timerId)
    },
    async mounted () {
      if (!this.$route.query.segmentId) {
        await this.fetchData('mouted')
      }
      this.tableOptions.sortBy = []
      this.tableOptions.sortDesc = [false]
      this.timerId = setInterval(() => {this.$router.go(0)}, 60 * 60 * 1000)
    },
    methods: {
      resetPagination () {
        this.tableOptions.page = 1
        this.tableOptions.itemsPerPage = 25
      },
      editSidePanel (item) {
        this.$set(this.sidePanelStatus, 'mode', true)
        this.$set(this.sidePanelStatus, 'data', item)
        this.$set(this.sidePanelStatus, 'active', true)
      },
      getLastActivity (date) {
        if (date) return 'Был(а) в сети ' + this.$moment.utc(date).local().format(this.$config.date.DATETIME_FORMAT_MIN2)
        return 'Был(а) в сети - '
      },
      getDate (date) {
        if (date) return this.$moment.utc(date).local().format(this.$config.date.DATE_FORMAT)
        return '-'
      },
      getTime (date) {
        if (date) return 'в ' + this.$moment.utc(date).local().format('HH:mm')
        return 'в -'
      },
      async fetchData (caller) {
        console.log('fetchData', caller)

        if (!this.loadingList) {
        

          try {
            this.loadingList = true
            this.cancelTokenSource = CancelToken.source()

            let filter;
            if (this.filter.type) {
              filter = {
                  type: this.filter.type,
                  segment_id: this.filter.segment_id,
                  rules: this.filter.rules,
                }
            } else {
              filter = null;
            }

            const payload = {
              program_id: this.program.id,
              search: this.search,
              filter: this.queryValue,
              filter: filter,
              limit: this.tableOptions.itemsPerPage,
              offset: this.getOffset,
              sortable: this.getSortable(this.tableOptions.sortBy, this.tableOptions.sortDesc),
            }
            await this.$store.dispatch('crm/client/list', { postData: payload, cancelToken: this.cancelTokenSource.token })
          } catch (err) {
            console.error(err)
          } finally {
            this.loadingList = false
          }
        }
      },
      getSortable (sortBy, sortDesc) {
        let sortable = null
        console.log('SORTBY, SORTDESC')
        console.log(sortBy, sortDesc)
        if (sortBy && sortDesc &&
          Array.isArray(sortBy) && Array.isArray(sortDesc) &&
          sortBy.length === sortDesc.length - 1) {
          //
          sortable = []
          sortBy.forEach((item, i) => {
            sortable.push({
              column: item,
              direction: sortDesc[i] ? 'DESC' : 'ASC',
            })
          })
        }
        return sortable
      },
    },
  }
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/crm/_crm.scss";
</style>
