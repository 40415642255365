<template>
<div>
  <v-row>
    <v-col class="d-flex align-center" cols="12">
      <v-row>
        <v-col>
          <base-text-field v-model="search" placeholder="Поиск" prefix="+" clearable >
            <template v-slot:prepend-inner>
              <v-btn
                v-if="selectSegment === null"
                v-on:click.stop="showFilter"
                :color="filterBtnColor"
                style="margin-left: -16px;"
              >
                <v-icon left> $iconify_mdi-filter-variant </v-icon>
                Фильтр
              </v-btn>

              <segment-chip
                v-else
                :chip="true"
                :close="true"
                @click="showFilter"
                @click:close="closeSegmentChip"
                :name="selectSegment.name"
                :color="selectSegment.color"
              >
              </segment-chip>
            </template>
          </base-text-field>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
      <message-box
        v-model="showFilterFlag"        
        :show-actions="false"
        max-width="80%"
        title="Фильтр клиентов"
        scrollable   
      >
      <v-row style="background-color: white; padding: 10px; margin: 0px">
        <v-col cols="4" style="max-width: 500px">
          <base-text-field
            placeholder="Поиск сегментов..."
            :prepend-inner-icon="'$iconify_search-outlined'"
            v-model="inputSearchSegment"
            clearable
          >
          </base-text-field>
          <v-list-item-group color="primary">
            <v-list>
              <v-list-item
                @click="selectionSegment(segment)"
                :class="'text-truncate' + checkItemSelection(segment)"
                v-for="segment in segmentsSearch"
                :key="segment.id"
                >{{ segment.name }}</v-list-item
              >
            </v-list>
          </v-list-item-group>
        </v-col>
        <v-col cols="auto"> <v-divider vertical /> </v-col>
        <v-col>
          <v-form ref="queryBuilderForm" v-model="queryValid">
          <clients-query-builder exportAndImportEnabled v-model="rules" />
        </v-form>
          <v-row class="mt-2">
            <v-col cols="auto">
              <v-btn @click="customFilterSearch" color="primary"> Найти </v-btn>
            </v-col>
            <!-- <v-col cols="auto">
              <v-btn color="success"> Сохранить </v-btn>
            </v-col> -->
            <v-col cols="auto">
              <v-btn @click="clearFilter" color="secondary"> Сброс </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
            </message-box>
            </div>

</template>

<script>
import ClientsQueryBuilder from "@/components/account/ClientsQueryBuilder.vue";
import { mapActions, mapGetters } from "vuex";
import SegmentChip from "@/components/segment/SegmentChip";

export default {
  name: "FilterClient",
  components: {
    ClientsQueryBuilder,
    SegmentChip,
    MessageBox: () => import('@/components/message-box/MessageBox.vue'),
  },
  data() {
    return {
      showFilterFlag: false,
      segmentPage: 1,
      inputSearchSegment: "",
      selectSegment: null,
      search: "",
      rules: null,
      queryValid: true,
      saveRules: {},
      firstSelectSegment: false
    };
  },
  async mounted() {
    await this.getSegments({ program_id: this.program.id });
    this.setSearch({search: ''})

    if (this.routeSegmentId) {
      this.selectionSegment(this.segments.filter(x => x.id == this.routeSegmentId)[0])
    } else { 
      this.sessionInit()
    }
  },
  methods: {
    ...mapActions({
      getSegments: "crm/segment/segments",
      setFilter: "crm/client/setFilter",
      setSearch: "crm/client/setSearch"
    }),
    showFilter() {
      this.showFilterFlag = !this.showFilterFlag;
    },
    closeSegmentChip() {
        this.selectSegment = null
        this.setFilter({filter: { rules: [], segment_id: null, type: null}})
    },
    selectionSegment(segment) {
      this.selectSegment = segment;
      this.setFilter({filter: { rules: [], segment_id: segment.id, type: "SEGMENT"}})
      this.showFilterFlag = false;
    },
    customFilterSearch(form = true) {
      if (form && !this.$refs.queryBuilderForm.validate()) return;

      this.saveRules = this.rules;
      this.setFilter({filter: { rules: this.saveRules, type: "RULES"}})     
      this.showFilterFlag = false;
    },
    clearFilter() { 
      this.rules = null
      this.saveRules = {}
      this.setFilter({filter: {}})
      this.showFilterFlag = false;
    },
    changeProgram() {
        this.rules = null
        this.saveRules = {}
        this.sessionInit()
        this.showFilterFlag = false;
    },
    sessionInit() {
      /*if (this.$session.has('сlientFilter' + this.program.id)) {
      let session = this.$session.get('сlientFilter' + this.program.id)

      if (session.type) {
        if (session.type == "SEGMENT") {
          this.selectionSegment(this.segments.filter(x => x.id == session.segment_id)[0])
        }

        if (session.type == "RULES") {
          this.selectSegment = null;
          this.rules = session.rules
          this.customFilterSearch(false)
        }
       }
      }*/
    },
    checkItemSelection(segment) {
      if (this.selectSegment && segment.id == this.selectSegment.id) return "v-item--active v-list-item--active";
      else return " ";
    },
  },
  watch: {
    'program.id' (v) {
        if (v) {
          this.changeProgram()
          this.getSegments({
          program_id: this.program.id,
        }); 
        }
      },
      sessionFilter(newValue) {
        let setFilterSession = {}

        if (newValue.filter.type == null || (newValue.filter.type == "RULES" && newValue.filter.rules.children.length == 0)) {
          this.$session.remove('сlientFilter' + this.program.id)
          return
        }

        if (newValue.filter.type == "SEGMENT") {
          setFilterSession.type = "SEGMENT"
          setFilterSession.segment_id = newValue.filter.segment_id
        }

        if (newValue.filter.type == "RULES") {
          setFilterSession.type = "RULES"
          setFilterSession.rules = newValue.filter.rules
        }

        this.$session.set('сlientFilter' + this.program.id, setFilterSession);
      },
      search(newValue) {
          if (newValue == null|| newValue.length >= 3 || newValue.length == 0) {
            this.setSearch({search: newValue})
          }
      },
      showFilterFlag(newValue) {
        if (newValue && this.saveRules.children !== undefined && this.rules.children !== this.saveRules.children) {
          this.rules.children = this.saveRules.children
        }
      }
  },
  props: ['routeSegmentId'],
  computed: {
    ...mapGetters({
      segments: "crm/segment/segments",
      program: "company/program/program",
      filter: "crm/client/filter",
      searchGetter: "crm/client/search"
    }),
    segmentsSearch() {
      const inputSearchSegment = this.inputSearchSegment?.trim().toLowerCase()
      if (inputSearchSegment) {
        return this.segments.filter(
          (item) => {
            return item.name.toLowerCase().includes(inputSearchSegment)
          }
        );
      }
      else {
        return this.segments;
      }
    },
    filterBtnColor() {
      return this.saveRules?.children?.length > 0 ? 'success' : 'primary'
    },
    sessionFilter () {
      return { filter : this.filter, search : this.searchGetter }
     }
  },
};
</script>
