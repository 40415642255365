<template>
  <base-drawer-dialog
    v-model="state"
    :width="483"
    class="panel-crm panel-crm_new_client"
    title="Новый клиент"
  >
    <v-form
      ref="panel-crm_new_client__form"
      v-model="valid"
      class="panel-crm_new_client__form"
    >
      <div v-if="showDescription" class="panel-crm_segment__form-labels">
        <p class="body-l-semibold">
          Персональные данные клиента
        </p>
        <p class="labels__sub-label body-m-regular">
          Укажите пресональные данные клиента, которые вы знаете для наиболее
          полного заполнения профиля.
        </p>
      </div>
      <div class="panel-crm_segment__form-block">
        <v-row>
          <v-col
            cols="12"
            style="height: 65px"
          >
            <base-text-field
              v-model="form.fio"
              :rules="[
                v => !!v || 'ФИО обязательно',
                v => String(v).length <= 50 || 'Имя должно быть не более 50 символов',
              ]"
              class="panel-crm__form-input panel-crm_new_client__form-input"
              type="text"
              placeholder="ФИО"
              outlined
              error-style="tooltip"
              validation-placement="top"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            style="height: 65px"
          >
            <!-- <base-text-field
                v-model="form.phone"
                v-mask="'+#############'"
                _v-mask="phoneMask"
                :rules="phoneRules"
                :error-messages="phoneErrors"
                class="panel-crm__form-input panel-crm_new_client__form-input"
                type="text"
                placeholder="Номер телефона"
                outlined
                error-style="vuetify"
                @keydown="onKeydown"
                @input="(v) => { if (!v) phoneMask= '+7 (###) ###-##-##' }"
              /> -->
            <vue-tel-input-vuetify
              ref="form_phone"
              :phone.sync="form.phone"
              placeholder="Номер телефона"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <base-text-field
              v-model="form.refer_code"
              v-mask="'########'"
              :rules="[
                (v) => (!v || v.length === 8 ) || 'Не верный код'
              ]"
              placeholder="Реферальный код"
              error-style="tooltip"
              validation-placement="top"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
          v-model="form.email"
          placeholder="Введите еmail"
          class="auth-text-field"
          outlined
          clearable
          :rules="emailRules"
          :validate-on-blur="false"
          hide-details="auto"
        >
        </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="8"
            style="height: 65px"
          >
            <base-datetime-picker-field
              v-model="form.birthday"
              :with-time="false"
              :max-date="$moment().toDate()"
              placeholder="Дата рождения"
              class="panel-crm__form-input panel-crm_new_client__form-input"
              clearable
            />
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="form.gender"
              class="mt-0"
              hide-details
              row
            >
              <v-radio
                v-for="item in genderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="form.consent_sms"
              class="panel-crm_new_client__switch"
              hide-details
            >
              <span
                slot="label"
                class="panel-crm_new_client__switch-text body-m-medium neutral-900--text"
              >Согласие на СМС уведомления</span>
            </v-switch>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="form.consent_advertising"
              class="panel-crm_new_client__switch"
              hide-details
            >
              <span
                slot="label"
                class="panel-crm_new_client__switch-text body-m-medium neutral-900--text"
              >Согласие на рекламу</span>
            </v-switch>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="form.sms_invite"
              class="panel-crm_new_client__switch"
              hide-details
            >
              <span
                slot="label"
                class="panel-crm_new_client__switch-text body-m-medium neutral-900--text"
              >Отправить смс-приглашение</span>
            </v-switch>
          </v-col>
          <v-col cols="12">
            <v-expansion-panels v-if="active">
              <v-expansion-panel class="expasion-panel_fields">
                <v-expansion-panel-header>
                  <p class="body-l-semibold mb-0">
                    Дополнительные поля
                  </p>
                  <template v-slot:actions>
                    <v-icon color="neutral-700">
                      $iconify_feather-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <client-fields
                    v-if="fields && fields.length"
                    ref="clientFields"
                    :fields-data.sync="fieldsData"
                    :valid-form.sync="validClientFieldsForm"
                    not-filled
                  />
                  <v-btn
                    v-else
                    class="btn btn-setting align-self-center"
                    color="primary"
                    @click="routeSettings()"
                  >
                    <iconify-icon
                      class="icon icon-success"
                      icon="feather-settings"
                      width="21"
                    />
                    <p
                      class="body-m-semibold mb-0"
                    >
                      Настроить
                    </p>
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-btn
              v-else
              class="btn btn-setting align-self-center"
              color="primary"
              @click="routeSettings()"
            >
              <iconify-icon
                class="icon icon-success"
                icon="feather-settings"
                width="21"
              />
              <p
                class="body-m-semibold mb-0"
              >
                Настроить
              </p>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="body-l-semibold">
                  Комментарий
                  <template v-slot:actions>
                    <v-icon color="neutral-700">
                      $iconify_feather-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea
                    v-model="form.comment"
                    :rows="3"
                    auto-grow
                    placeholder="Введите комментарий (необязательно)"
                    outlined
                    maxlength="4096"
                    hide-details
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12">
            <v-btn
              class="panel-crm_new_client__btn-add-client"
              color="primary"
              :loading="loading"
              :disabled="!valid || !validClientFieldsForm"
              @click="addClient"
            >
              <iconify-icon
                class="icon-check-circle"
                icon="check-circle"
                height="21"
              />
              <p class="body-m-semibold neutral-100--text">
                Добавить клиента
              </p>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </base-drawer-dialog>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import { mapActions, mapGetters } from 'vuex'
  import Convertor from '@/mixins/convertor.js'
  import { validPhone } from '@/utils/validate'
  import ClientFields from '@/components/client/Fields'
  import { validEmail } from '@/utils/validate.js'

  export default {
    directives: { mask },
    components: {
      ClientFields,
    },
    mixins: [Convertor],
    model: {
      prop: 'active',
      event: 'changeState',
    },
    props: {
      active: {
        type: Boolean,
      },
      showDescription: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        valid: true,
        emailRules: [
          v => ( !v || validEmail(v)) || 'E-mail неверного формата',
        ],
        loading: false,
        fieldsData: {},
        validClientFieldsForm: true,
        form: {
          fio: null,
          phone: null,
          birthday: null,
          sms_invite: false,
          consent_sms: false,
          consent_advertising: true,
          comment: null,
          email: null
        },
        phoneMask: '+7 (###) ###-##-##',
        phoneErrors: [],
        phoneRules: [
          v => (!!v && validPhone(v)) || 'Введите телефон',
        ],
        state: this.active,
      }
    },
    computed: {
      ...mapGetters({
        settFields: 'settings/account-fields/fields',
        programId: 'programId',
      }),
      program () {
        return this.$store.getters['company/program/program']
      },
      genderList () {
        return [
          { value: 'm', label: 'Мужчина' },
          { value: 'f', label: 'Женщина' },
        ]
      },
      fields () {
        return this.settFields?.[this.programId]
      },
    },
    watch: {
      active (v) {
        this.state = v
        if (v) {
          this.$refs['panel-crm_new_client__form'].reset()
          this.$nextTick(() => {
            this.$refs.form_phone.initCountryCode()
          })

          this.form.fio = null
          this.form.phone = null
          this.form.birthday = null
          this.form.sms_invite = false
          this.form.consent_sms = false
          this.form.consent_advertising = true
          this.form.email = null
          this.fieldsData = {}
        }
      },
      state (v) {
        this.$emit('changeState', v)
      },
      'form.phone' (v) {
        if (v) {
          const phone = this.clearPhoneMask(v)
          if (phone.length === 11) {
            this.phoneErrors = []
          }
        }
      },
      async programId (v) {
        await this.getSettingsFields({ id: v, withStub: false })
      },
    },
    async mounted () {
      await this.getSettingsFields({ id: this.programId, withStub: false })
    },
    methods: {
      ...mapActions({
        CreateAccountFields: 'crm/account-fields/CreateAccountFields',
        getSettingsFields: 'settings/account-fields/GetFields',
        checkReferCode: 'crm/account-referral/checkReferCode',
      }),
      onKeydown (e) {
        // console.log('onKeydown', e)
        if (!this.form.phone && e.key === '8') {
          this.phoneMask = '8 (###) ###-##-##'
        }
      },
      close () {
        this.$emit('changeState', false)
      },
      clearPhoneMask (p) {
        if (!p || (p && !p.length)) return ''

        p = String(p).match(/\d/g)
        if (p) p = p.join('')
        return p
      },
      async addClient () {
        if (!this.$refs['panel-crm_new_client__form'].validate()) return false
        const phone = this.clearPhoneMask(this.form.phone)
        // if (phone && phone.length !== 11) {
        //   this.phoneErrors = ['Введите номер телефона']
        //   return false
        // }
        let referCode = this.form.refer_code
        try {
          this.loading = true
          if (this.form.refer_code) {
            // check refer_code
            const referCodeValid = await this.checkReferCode({ program_id: this.program.id, refer_code: referCode })
            if (!referCodeValid) {
              await this.$confirm(
                'Реферальные код не действителен.<br/>Продолжить регистрацию клиента без реферального кода?',
                'Регистрация клиента',
                {
                  confirmButtonText: 'Да',
                  cancelButtonText: 'Отмена',
                  type: 'warning',
                },
              )
              referCode = null
            }
          }

          let birthDay = this.$moment.utc(this.form.birthday).local().format('DD.MM.YYYY')
          const bdayRegExp = new RegExp('^(?:0*[1-9]|[12]\\d|3[01])([\\/.-])(?:0*[1-9]|1[012])\\1(?:19|20)\\d\\d$')

          if (!bdayRegExp.test(birthDay)) {
            birthDay = null
          }
          const item = {
            program_id: this.program.id,
            fio: this.form.fio,
            phone,
            refer_code: referCode,
            birthday: birthDay,
            sms_invite: this.form.sms_invite,
            consent_sms: this.form.consent_sms,
            consent_advertising: this.form.consent_advertising,
            gender: this.form.gender,
            comment: this.form.comment,
            email: this.form.email,
          }
          console.log(item)
          const clientCard = await this.$store.dispatch('crm/client/create', item)
          this.updateFields(clientCard)
          this.close()
          this.$emit('dataCreateClient', clientCard)
          this.$emit('success')
          this.close()
        } finally {
          this.loading = false
        }
      },
      async updateFields (clientCard) {
        console.log('updateField clientCard:', clientCard)
        if (this.$refs.clientFields.$refs.formFields.validate()) {
          const prepared = []

          Object.keys(this.fieldsData).forEach(key => {
            if (typeof this.fieldsData[key] === 'object') {
              prepared.push({ id: key, value: this.fieldsData[key].value })
            } else {
              prepared.push({ id: key, value: this.fieldsData[key] })
            }
          })

          await this.CreateAccountFields({ account_id: clientCard.id, fields: prepared })
        } else {
          this.$notify({
            type: 'error',
            title: 'Дополнительных поля',
            text: 'Ошибки валидации дополнительных полей',
          })
        }
      },
      routeSettings () {
        this.$router.push({ name: 'SettingsCRM' })
      },
    },
  }
</script>

<style lang="scss" scoped>
.panel-crm_new_client {
  & ::v-deep {
    .side-panel__navigation {
      z-index: 7!important;
    }
  }
  .btn-setting {
    & ::v-deep {
      .icon {
        margin-right: 8px;
      }
    }
  }
}
</style>
